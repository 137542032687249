import React from 'react';
import PropTypes from 'prop-types';
import 'iframe-resizer/js/iframeResizer';

import { setDangerousHtmlAsElementChildren } from '../../utils/browserUtils';

import './styles.scss';

/**
 *
 * @visibleName Contentfry Display
 * @version 2
 */
class Contentfry extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            idBlock: `idBlockContentfry${parseInt(Math.random() * 10000, 10)}`,
        };

        this.refData = {
            refIdBlock: null,
        };

        this.setRefBlockId = (element) => {
            if (element) {
                const { id } = element;
                this.refData.refIdBlock = id;
            }
        };

        this.applyNewHtml = this.applyNewHtml.bind(this);
        this.applyComponentChanges = this.applyComponentChanges.bind(this);
        this.getBlockCss = this.getBlockCss.bind(this);
        this.iFrameResizeHandler = this.iFrameResizeHandler.bind(this);
    }

    componentDidMount() {
        this.applyComponentChanges();
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState) {
        this.applyComponentChanges();
    }

    getBlockCss() {
        const {
            blockCss,
        } = this.props;

        return blockCss ? `<style>${blockCss}</style>` : '';
    }

    applyComponentChanges() {
        const {
            displayUrl,
        } = this.props;

        if (displayUrl) {
            this.applyNewHtml('');
            document.addEventListener('load', this.iFrameResizeHandler);
        }
    }

    applyNewHtml(html) {
        const { refIdBlock } = this.refData;
        setDangerousHtmlAsElementChildren(html, refIdBlock);
    }

    iFrameResizeHandler() {
        const { refIdBlock } = this.refData;
        const iframeResizeProps = { log: true };
        /* eslint-disable */
        if (typeof iFrameResize !== 'undefined' && iFrameResize) {
            iFrameResize(iframeResizeProps, `#${refIdBlock}`);
        } else {
            console.warn('[APP] `iFrameResize` is not defined.');
        }
        /* eslint-enable */
        document.removeEventListener('load', this.iFrameResizeHandler);
        return false;
    }

    renderIframe() {
        const {
            anchorId,
            blockCss,
            blockStyleId,
            displayUrl,
            modificators,
        } = this.props;

        const {
            idBlock,
        } = this.state;

        const className = 'contentfry-content';
        const classNames = [className, blockStyleId]
            .concat(modificators.map(cname => `${className}--${cname}`))
            .filter(Boolean)
            .join(' ');
        /* eslint-disable react/no-danger */
        return (
            <>
                {anchorId &&
                    /* eslint-disable-next-line jsx-a11y/anchor-has-content, jsx-a11y/anchor-is-valid */
                    <a id={anchorId} />
                }
                {blockCss &&
                    <style>{blockCss}</style>
                }
                <iframe
                    id={idBlock}
                    ref={this.setRefBlockId}
                    className={classNames}
                    data-hj-allow-iframe=""
                    data-component-version={'v2.0'}
                    src={displayUrl}
                    title={idBlock}
                    width="100%"
                />
            </>
        );
        /* eslint-enable react/no-danger */
    }

    renderInnerHtml() {
        const {
            anchorId,
            blockCss,
            blockStyleId,
            innerHtml,
            modificators,
        } = this.props;

        const {
            idBlock,
        } = this.state;

        const className = 'contentfry-content';
        const classNames = [className, blockStyleId]
            .concat(modificators.map(cname => `${className}--${cname}`))
            .filter(Boolean)
            .join(' ');
        return (
            <>
                {anchorId &&
                    /* eslint-disable-next-line jsx-a11y/anchor-has-content, jsx-a11y/anchor-is-valid */
                    <a id={anchorId} />
                }
                {blockCss &&
                    <style>{blockCss}</style>
                }
                <div
                    id={idBlock}
                    ref={this.setRefBlockId}
                    className={classNames}
                    /* eslint-disable react/no-danger */
                    dangerouslySetInnerHTML={{ __html: innerHtml }}
                />
            </>
        );
    }

    render() {
        const {
            displayUrl,
            innerHtml,
        } = this.props;

        if (displayUrl) {
            return this.renderIframe();
        }

        if (innerHtml) {
            return this.renderInnerHtml();
        }

        return null;
    }
}

Contentfry.defaultBlockCss = `
/* $tablet-portrait */
@media (min-width: 600px) {
    .contentfry-content {
    }
}

/* $tablet-landscape */
@media (min-width: 900px) {
    .contentfry-content {
    }
}

/* $desktop */
@media (min-width: 1140px) {
    .contentfry-content {
    }
}
`;

Contentfry.formFields = Object.assign({}, {
    anchorId: <input />,
    displayUrl: <input />,
    innerHtml: <textarea />,
    blockStyleId: <input />,
    blockCss: <textarea placeholder={Contentfry.defaultBlockCss} />,
});

Contentfry.propTypes = {
    anchorId: PropTypes.string,
    blockCss: PropTypes.string,
    blockStyleId: PropTypes.string,
    displayUrl: PropTypes.string,
    innerHtml: PropTypes.string,
    modificators: PropTypes.arrayOf(PropTypes.string),
};

Contentfry.defaultProps = {
    anchorId: '',
    blockCss: '',
    blockStyleId: '',
    displayUrl: '',
    innerHtml: '',
    modificators: [],
};

export default Contentfry;
